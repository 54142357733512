<template>
  <div class="container">
    <div class="booking_failedbox" v-if="orderFailed">
      <fo-booking-order-failed />
    </div>
    <div class="flightbooking_form" v-else>

      <div class="flightbooking_top">
        <h3>{{$t("sabre.booking.flight-booking")}}</h3>
      </div>

      <div class="row g-4">
        <div class="col-lg-8 col-md-12 w75">

          <flight-booking-form-right-box />

        </div>
        <div class="col-lg-4 col-md-12 w35">

          <order-detail-area />

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'booking-flight-area',
  components: {
    FlightBookingFormRightBox: () => import('./flightBookingForm/flightBookingFormRightBox'),
    OrderDetailArea: () => import('./orderDetailArea/orderDetailArea'),
    FoBookingOrderFailed: () => import('@/sabre/common/atom/foBookingOrderFailed'),
  },
  data() {
    return {
      orderFailed: false,
    };
  },
};
</script>
